import { environment } from "../../../environments/environment";
import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root"
})
export class RestApi {
  // public readonly VEEVA_API_URL = environment.veevaauthUrl;
  public readonly API_URL = environment.authUrl;

  public readonly SWAGGER_URL = environment.swaggerUrl;

  public readonly API_URL_services = environment.baseUrl;

  public readonly API_URL_SPRING_BASE = environment.springBase;



  //public readonly API_URL_MIGRATION_TOOLS = environment.baseUrl;

  public readonly SWAGGERURL = this.SWAGGER_URL;

  public readonly GET_TABLE_DATA_URL = this.API_URL_services + "/commonRetrieveRecords";

  public readonly CREATE_RECORD_URL = this.API_URL_services + "/commonCreateRecord";

  public readonly UPDATE_RECORD_URL = this.API_URL_services + "/commonUpdateRecord";

  public readonly DELETE_RECORD_URL = this.API_URL_services + "/commonDeleteRecord";
  //delete record from db
  public readonly DELETE_RECORD_PERMANENT_URL = this.API_URL_services + "/deleteRecord";

  public readonly GET_TRNXTABLE_DATA_URL = this.API_URL_services + "/trnxRetrieveRecords";

  public readonly GET_TRNX_TABLE_DATA_FOR_DROPDOWNS = this.API_URL_services + "/trnxRetrieveRecordsForDropDowns";

  public readonly GET_CHILDTRNXTABLE_DATA_URL = this.API_URL_services + "/childtrnxRetrieveRecords";

  public readonly CREATE_TRNXRECORD_URL = this.API_URL_services + "/trnxCreateRecord";


  public readonly DELETE_TRNXMSTR_RECORD_URL = this.API_URL_services + "/deleteRecordFromMaster";

  public readonly CREATE_TRNXCHILDRECORD_URL = this.API_URL_services + "/trnxCreateChildRecord";

  public readonly UPDATE_TRNXRECORD_URL = this.API_URL_services + "/trnxUpdateRecord";

  public readonly DELETE_TRNXRECORD_URL = this.API_URL_services + "/trnxDeleteRecord";

  //LOGIN MODULE API
  //public readonly LOGIN_URL = this.API_URL + "veevasignin";
  public readonly VEEVALOGIN_URL = this.API_URL + "/veevasignin/Web";

  public readonly LOGIN_URL = this.API_URL + "/signin/Web";
  //public readonly LOGIN_VEEVA_URL = this.VEEVA_API_URL;

  public readonly forgot_URL = this.API_URL + "/forgotpassword";

  public readonly reset_Password_Mail = this.API_URL + "/resetpasswordmail";

  public readonly logOut_URL = this.API_URL_services + "/logout";

  public readonly loginReplace_URL = this.API_URL + "/replace/Web";

  public readonly veevaLoginReplace_URL = this.API_URL + "/replaceVeevaUser/Web";

  public readonly MICROSOFT_LOGIN_URL=this.API_URL+"/microsoftGenerateToken"

  public readonly RETRIEVE_TOWNSHIPMASTERFORLOGIN_URL = this.API_URL + "/retrieveTownshipMaster";

  public readonly RESETPWD_URL = this.API_URL_services + "/resetpassword";

  // Customer Support API
  public readonly CUSTOMERSUPPORT_URL = this.API_URL + "/insertSupportingMail";

  public readonly INSERT_SCREEN_CREATOR_URL = this.API_URL_SPRING_BASE + "/insertScreenCreator";

  public readonly RETRIEVE_SCREEN_CREATOR_URL = this.API_URL_SPRING_BASE + "/retrieveScreenCreator";

  public readonly UPDATE_SCREENCREATOR_URL = this.API_URL_SPRING_BASE + "/updateScreens";

  public readonly RETRIEVE_SCREEN_CREATOR_COMPLETE_DATA_URL = this.API_URL_SPRING_BASE + "/retrieveScreenCreatorCompleteData";

  public readonly RETRIEVE_SCREEN_BY_TABLE_NAME_URL = this.API_URL_SPRING_BASE + "/retrieveScreenByTableName";

  //Screens
  public readonly retrieveScreenNames = this.API_URL_SPRING_BASE + "/retrieveScreens";

  public readonly RETRIEVE_SCREENNAMES_FORDROPDOWN = this.API_URL_SPRING_BASE + "/retrieveScreensForDropDown";

  public readonly Retrieve_Transaction_Screen_Creator_Fields_URL = this.API_URL_SPRING_BASE + "/retrieveTransactionScreenCreatorFields";

  public readonly RETRIEVE_TRNXSCREEN_BY_TABLE_NAME_URL = this.API_URL_SPRING_BASE + "/retrieveTrnxScreenByTableName";

  public readonly RETRIEVE_TRNXCHILDSCREEN_BY_TABLE_ID_URL = this.API_URL_SPRING_BASE + "/retrieveTrnxScreenByParentScreenId";

  public readonly Insert_Transaction_Screen_Creator_URL = this.API_URL_SPRING_BASE + "/insertTrnxScreenCreator";

  public readonly RETRIEVE_SCREEN_CREATOR_TRANSACTION_MASTER_URL = this.API_URL_SPRING_BASE + "/retrieveTrnxMasterScreenCreator";

  public readonly RETRIEVE_SCREEN_CREATOR_TRANSACTION_CHILD_URL = this.API_URL_SPRING_BASE + "/retrieveTrnxChildScreenCreator";

  public readonly GET_TABLE_SINGLE_RECORD_URL = this.API_URL_services + "/commonRetrieveSingleRecord";

  
  public readonly GET_TEST_SCRIPTS_FOR_SELECTED_DNS = this.API_URL_SPRING_BASE + "/commonRetrieveRecordByDnsId";

  //Name - Amol Nehere
  public readonly RETRIEVE_CONCRETE_UPDATE_RECORD = this.API_URL_SPRING_BASE + "/retrieveRequisitionForWeb"

  public readonly RETRIEVEDROPDOWN_URL = this.API_URL_services + "/getlistnostatuscheck";

  public readonly RETRIEVENAMEDROPDOWN_URL = this.API_URL_SPRING_BASE+"/retrieveObjectAttributeNames"

  public readonly RETRIEVE_TABLEDATA_URL = this.API_URL_services + "/retrievetabledata";

  public readonly UPDATECONCRETE_URL = this.API_URL_SPRING_BASE + "/updateRequisition";

  public readonly DELETE_TABLE_URL = this.API_URL_services + "/deleteTableAndScreen";

  public readonly RETRIEVEDISPATCHHISTORYDATA_URL = this.API_URL_SPRING_BASE + '/retrieveRequisitionDispatchHistory';

  public readonly RETRIEVERETURNEDORDERS_URL = this.API_URL_SPRING_BASE + '/retrieveReturnedOrdersForWeb';

  public readonly UPDATERETURNEDORDERS_URL = this.API_URL_SPRING_BASE + '/updateReturnedOrdersForWeb';

  public readonly UPADTE_USER_RECORD_URL = this.API_URL_SPRING_BASE + "/updateUserRecord";

  public readonly RETRIEVEBATCHPLANTDATA_URL = this.API_URL_SPRING_BASE + '/retrieveBatchPlantScheduling';

  public readonly INSERTBATCHPLANTDATA_URL = this.API_URL_SPRING_BASE + '/insertBatchPlantScheduling';

  public readonly UPDATEBATCHPLANTDATA_URL = this.API_URL_SPRING_BASE + '/updateBatchPlantScheduling';

  public readonly DELETEBATCHPLANTDATA_URL = this.API_URL_SPRING_BASE + '/deleteBatchPlantScheduling';

  /**@author Umashankar, @date 25-2-2021, @description url is used to retrieve batch plant data for schedule calendar */

  public readonly RETRIEVEBATCHPLANTDATAFORSCHEDULECALENDAR_URL = this.API_URL_SPRING_BASE + '/retrieveBatchPlantSchedulingCalendar';

  /**@author Umashankar, @date 01-03-2021, @description url is used to retrieve batch plant list for schedule master */

  public readonly RETRIEVEBATCHPLANTLISTFORSCHEDULECALENDAR_URL = this.API_URL_services + '/trnxRetrieveRecords';

  public readonly RETRIEVE_OBJECTS_URL = this.API_URL_SPRING_BASE + '/retrieveReporting';

  public readonly RETRIEVE_TABLES_FIELDS_URL = this.API_URL_SPRING_BASE + '/retrieveReportingTableFields';

  //Inset Reports API
  public readonly INSERT_REPORT = this.API_URL_SPRING_BASE + "/insertReports";

  public readonly INSERT_MIGRATION_JSONS_URL = this.API_URL_SPRING_BASE + "/insertMigrationJsons";

  public readonly RETRIEVE_QUICKSIGHT = this.API_URL_SPRING_BASE + "/getQuickSight";

  public readonly RETRIEVE_ANALYSIS = this.API_URL_SPRING_BASE + "/getAnalysisById";

  public readonly RETRIEVE_ANALYSISBYID = this.API_URL_SPRING_BASE +"/getAnalysis";

  public readonly INSERT_REPORTINSTANCEFROMQUICKSIGHT = this.API_URL_SPRING_BASE +"/InsertReportInstanceFromQuickSight";

  /**@author Umashankar, @date 21-4-2021, @description Url is used to extract the data */
  public readonly GETEXTRACTDATA_URL = this.API_URL_SPRING_BASE + "/extractDocuments";

  /**@author Umashankar, @date 21-4-2021, @description Url is used to extract the data */
  public readonly CONVERTTOJSONDATA_URL = this.API_URL_SPRING_BASE + "/buildJson";

  /**@author Umashankar, @date 21-4-2021, @description Url is used to extract the data */
  public readonly LOADTOS3DATA_URL = this.API_URL_SPRING_BASE + "/uploadJson";

  public readonly RETRIEVE_MASTER_DATA_LOAD_LOG = this.API_URL_SPRING_BASE + "/retrieveMasterDataLoadLog";

  public readonly INSERT_EXTRACT_BUILD_UPLOAD = this.API_URL_SPRING_BASE + "/extractBuildUpload";

  //elastic search api
  public readonly GET_ELASTIC_SEARCH_DATA = this.API_URL_SPRING_BASE + "/returnElastic";

  public readonly GET_ELASTIC_SEARCH_FILTER_DATA = this.API_URL_SPRING_BASE + "/returnElasticfilter";

  public readonly RETRIEVE_REPORTLINKS_URL = this.API_URL_SPRING_BASE + "/retrieveReportCreation";

  public readonly RETRIEVE_REPORT_URL = this.API_URL_SPRING_BASE + "/retrieveReportCreationBasedOnOwnerName";

  public readonly INSERT_REPORTLINKS_URL = this.API_URL_SPRING_BASE + "/insertReportCreation";
  
  public readonly FetchUserRole_URL = this.API_URL_SPRING_BASE +"/fetchUserRole/";

  public readonly FetchUserRoleAndName_URL = this.API_URL_SPRING_BASE +"/fetchUserRoleAndRoleName/";
  //Filters manage
  public readonly INSERT_FILTERS = this.API_URL_SPRING_BASE + "/insertFilterManage";
  public readonly UPDATE_FILTERS_LIST = this.API_URL_SPRING_BASE + "/updateFilterManage";
  public readonly RETRIEVE_FILTERS = this.API_URL_SPRING_BASE + "/retrieveFilterManage";
  public readonly UPDATE_FILTER = this.API_URL_SPRING_BASE +"/updateFilterRecord";
  // schedule and filters api
  public readonly RETRIEVE_SCHEDULE_URL = this.API_URL_services + "/retrievetabledata";
  public readonly RETRIEVE_FILTERS_URL = this.API_URL_services + "/commonRetrieveSingleRecord/retrieveinstanceschedules_view/templatecreationmstrid/1";
  public readonly INSERT_REPORTPOPUP_URL = this.API_URL_SPRING_BASE + "/insertReportInstance";
  public readonly UPDATE_SENTVAULT_URL = this.API_URL_SPRING_BASE + "/UpdateReportInstanceDetailsById"

  //Objectgroup api
  public readonly INSERT_OBJECTGROUP = this.API_URL_SPRING_BASE + "/createObjectGroup";
  public readonly RETRIEVE_OBJECTGROUP = this.API_URL_SPRING_BASE +"/retrieveObjectGroup";
  public readonly RETRIEVE_VAULTOBJECTS = this.API_URL_SPRING_BASE +"/retrieveObjectGroupByGroupName";
  public readonly DELETE_OBJECTGROUP = this.API_URL_SPRING_BASE +"/deleteMstrObjectGroup";
  public readonly UPDATE_OBJECTGROUP = this.API_URL_SPRING_BASE +"/updateObjectGroup";

  //Javers audit
  public readonly RETRIEVE_JAVERS_URL = this.API_URL_SPRING_BASE + "/javersauditretrieve";

  public readonly CHECKTABLE_EXISTS = this.API_URL_SPRING_BASE +"/CheckTableExistOrNot";
  //Audit Trail
  public readonly RETRIEVE_AUDIT_URL = this.API_URL_SPRING_BASE + "/reteriveAuditRecords";
  public readonly RETRIEVE_AUDIT_URL_IN_Range = this.API_URL_SPRING_BASE + "/reteriveAuditInRange";

  //objectAttributes
  public readonly RETRIEVE_OBJECTATTRIBUTES = this.API_URL_SPRING_BASE +"/retrieveObjectAttributesLog";
  public readonly INSERT_OBJECTATTRIBUTES = this.API_URL_SPRING_BASE +"/insertobjectattributes";

  public readonly DATABASE_NAMES = this.API_URL_services +"/commonRetrieveSingleRecord/vaultconfigmstr/vaultconfigmstrid";

  public readonly RETRIEVE_DATA_BY_NAME= this.API_URL_SPRING_BASE +"/retrieveobjectattributes";


  //migration tools
  public readonly RETRIEVE_TOOLS = this.API_URL_services  +"/getToolInfo";
  public readonly RETRIEVE_TOOL_LOG = this.API_URL_services  +"/toolServiceLogInformation/";
  public readonly XML_TO_CSV_SQL = this.API_URL_services  +"/xmlToCsvToSql";
  public readonly XML_MODIFIER = this.API_URL_services  +"/xmlModifier";
  public readonly XML_SPLITTER = this.API_URL_services  +"/xmlSplitter";
  public readonly CSV_SQL = this.API_URL_services  +"/csvToSql";
  public readonly ZIP_FILE_EXTRACT =this.API_URL_SPRING_BASE + "/zipfileextraction"
  public readonly Update_Case_State = this.API_URL_SPRING_BASE + "/updateLifeCycleState";
  public readonly UPDATE_PRODUCT_TYPE_CHANGE = this.API_URL_SPRING_BASE + "/updateProductType";
  public readonly RETRIEVE_CASE_STATE_USERACTIONS = this.API_URL_SPRING_BASE+"/retrieveLifeCycleStateAction";
  public readonly RETRIEVE_UPDATE_CASE_STATE =this.API_URL_SPRING_BASE + "/retrieveLifeCycleState"

 //retrive Vault DNs Access
 public readonly VAULT_DNS_ACCESS_DATA=this.API_URL_SPRING_BASE +"/retrieveVaultDNSAccess";
 public readonly VAULT_DNS_INSERT_DATA=this.API_URL_SPRING_BASE +"/insertVaultDNSAccess";


  //DNS Url
  public readonly RETRIEVE_USERDNS_URL = this.API_URL + "/retrieveuserdns";
  public readonly RETRIEVE_USERSELECTEDDNS_URL = this.API_URL +"/retrieveuserselecteddns";

  //Multiple object extraction
  public readonly INSERT_MULTIPLEOBJECTATTRIBUTES = this.API_URL_SPRING_BASE +"/objectsExtractor";


  //ETL
  public readonly TRANSFORMATION = this.API_URL_SPRING_BASE+"/transformSourceFiles"; // for transformation

  public readonly LOADING = this.API_URL_SPRING_BASE+"/vaultLoader";// for loading

  public readonly RESUMELOADING = this.API_URL_SPRING_BASE+"/vaultReloader";

  public readonly RESUMELOADFORPARALLEL = this.API_URL_SPRING_BASE+"/startReload";

  public readonly RETRIEVEDATA = this.API_URL_SPRING_BASE+"/retrieveVaultLoadInstructions";// to retrieve data and bind to ag grid

  public readonly STARTMIGRATION = this.API_URL_SPRING_BASE+"/insertMigrationParent";// to start migration process

  public readonly ABORTMIGRATION = this.API_URL_SPRING_BASE+"/updateMigrationParent";// to abort migration process

  public readonly GET_MIGRATIONTRACE_DATA = this.API_URL_SPRING_BASE+"/retrieveMigrationTrace";

  public readonly UPLOADMAPPINGFILE = this.API_URL_SPRING_BASE+"/uploadMappingFile";

  public readonly RETRIEVEMAPPINGFILE = this.API_URL_SPRING_BASE+"/retrieveMappingFile";

  public readonly DOWNLOADMAPPINGFILE = this.API_URL_SPRING_BASE+"/downloadMappingFile";

  public readonly downloadFileUrl=this.API_URL_SPRING_BASE+"/downloadFile"
  public readonly zipanddownloadfilesUrl=this.API_URL_SPRING_BASE+"/zipanddownloadfiles"
  public readonly DOWNLOADSOURCEFILE=this.API_URL_SPRING_BASE+"/downloadSourcefile"
  public readonly zipanddownloadanyfilesFromS3=this.API_URL_SPRING_BASE+"/zipanddownloadanyfilesFromS3"
  public readonly zipanddownloadanyfilesFromS3UsingPathOnly = this.API_URL_SPRING_BASE+"/zipanddownloadanyfilesFromS3UsingPathOnly"

    public readonly UPLOAD_EXTERNALFILE_S3 = this.API_URL_SPRING_BASE+"/uploadFileTospecificPath"
    public readonly DOWNLOAD_LIBRARY_OBJECT = this.API_URL_SPRING_BASE+"/downloadLibraryObject"


  public readonly DOWNLOADREPORTVALIDATIONFILE = this.API_URL_SPRING_BASE+"/downloadValidationReportFile";

  public readonly DOWNLOADREPORTSUMMARYFILE = this.API_URL_SPRING_BASE+"/downloadSummaryReportFile";

  public readonly DOWNLOADEXTRACTFILE = this.API_URL_SPRING_BASE+"/downloadExtractionFile";

  public readonly DOWNLODSOURCEFILE = this.API_URL_SPRING_BASE+"/downloadSourceFile"

  public readonly UPLOADSOURCEFILE = this.API_URL_SPRING_BASE+"/uploadSourceFile";

  public readonly UPLOADCSVFILE = this.API_URL_SPRING_BASE+"/uploadCsvFile";

  public readonly UPLOADSOURCEFILEPARALLEL = this.API_URL_SPRING_BASE+"/uploadSourceFileForload";

  // New ETL Screen api's

  public readonly REEXTRACTLIBRARYOBJECT = this.API_URL_SPRING_BASE+"/extractVaultObjectForTransformation";

  public readonly RETRIEVEVAULTOBJECTS = this.API_URL_SPRING_BASE+"/retrieveVaultLibraryObject";

  public readonly GET_TABLE_SINGLE_RECORD_FORETL = this.API_URL_SPRING_BASE + "/retrieveMigration";

  public readonly RUNMIGRATIONE2B = this.API_URL_SPRING_BASE + "/runE2BMigration";

  public readonly INSERTETOBTASK = this.API_URL_SPRING_BASE + "/insertetobtype";

  public readonly INSERTMAPPINGFILEETOB = this.API_URL_SPRING_BASE + "/insertetobfiel";

  public readonly RETRIEVEETOOBJECTS = this.API_URL_SPRING_BASE + "/retrieveE2BObjects";

  public readonly GENERATESUMMARYREPORT = this.API_URL_SPRING_BASE + "/generatevalsummaryreport";

  public readonly INSERTJSUITEMIGRATIONPARENT = this.API_URL_SPRING_BASE + "/insertjsuitemigrationparent";

  // api call to retrieve deffered objects. Written by kalpesh
  public readonly DEFFEREDOBJECTS = this.API_URL_services +"/commonRetrieveSingleRecord/loadobjectsmstr/tenantid";

  // Permission names
  public readonly retrievePermissionNames = this.API_URL_SPRING_BASE + "/retrieveScreensWeb";

  public readonly retrievebysubchildPermissionNames = this.API_URL_SPRING_BASE + "/screenoperationspermissionBasedOnroleId";

  // Permission Access
  public readonly updatePermissionAccess = this.API_URL_SPRING_BASE + "/insertAccessPermission";
  
  //ButtonPermissions to update DB
  public readonly insertIntoScreenOperationPermission = this.API_URL_SPRING_BASE + "/insertIntoScreenOperationPermission";

  // Assign User Role
  //retrieve Users For Assign User Role
  public readonly RETRIEVE_USERS_ASSIGN_USER_ROLE = this.API_URL_SPRING_BASE +"/retrieveUsersForAssignUserRole"

  //insert Assign User Role
  public readonly INSERT_ASSIGN_USER_ROLE = this.API_URL_SPRING_BASE +"/insertAssignUserRole"

  //retrieve Assign Users Role By DnsId
  public readonly RETRIEVE_ASSIGN_USER_ROLE_BYDNS_ID = this.API_URL_SPRING_BASE +"/retrieveAssignUsersRoleByDnsId"

  //is User Role available For User
  public readonly IS_USER_ROLE_AVAILABLE_FOR_USER = this.API_URL_SPRING_BASE + "/isUserRoleavailableForUser"

  //reterive user Role By Tenant Id And UserId
  public readonly RETERIVE_USER_ROLE_BY_TENANTID_AND_USERID = this.API_URL_SPRING_BASE + "/reteriveuserRoleByTenantIdAndUserId"

  public readonly RETRIEVEJSUITEPROPERTIES = this.API_URL_SPRING_BASE + "/retrievePropertyValueForLoad";

  // ETL update case data api's
  public readonly UPLOADOBJECTFILE = this.API_URL_SPRING_BASE + "/uploadSourceFileForUpateLoad";
  public readonly UPDATECASEDATA = this.API_URL_SPRING_BASE + "/initiateUpdateCaseLoad";
  public readonly DELETECASEDATA = this.API_URL_SPRING_BASE + "/deleteuploadedSourceFileForUpdateLoad";

  // JMigrator tool R3 Extractor api's

  public readonly UPLOADR3EXTRACTOR = this.API_URL_SPRING_BASE + "/ExtractDocumentsFromVault";

  public readonly RETRIEVER3EXTRACTOR = this.API_URL_SPRING_BASE + "/retrieveScheduledJobsByDnsId";


  public readonly INSERT_CSV_FILE_TO_S3 = this.API_URL_SPRING_BASE + "/insertcsvfiletoS3"

  public readonly GENERATESPLITREPORT = this.API_URL_SPRING_BASE + "/generatesplitreport";

  // UserRoleMstr Api's

  public readonly RETERIVE_USER_ROLEMSTR_BY_DNS_ID = this.API_URL_SPRING_BASE + "/reteriveUserRoleMstrByDnsId"

  //reterive multi and single object attribute by dnsid and source
  public readonly RETRIEVEOBJECTLABELBYDNSIDANDSOURCE = this.API_URL_SPRING_BASE +"/retrieveobjectLabelByDnsidAndSource"

  public readonly RETRIEVINGATTACHMENTLOADDETAILS = this.API_URL_SPRING_BASE+"/retrievingAttachmentLoadDetails"

  public readonly UPDATELOADREADYSTATUS = this.API_URL_SPRING_BASE  + "/attachmentLoad/updateLoadStatus"

  public readonly ATTACHMENTLINKTOCASE = this.API_URL_SPRING_BASE+ "/attachmentLinkToCase"

  public readonly RESUMEVAULTOBJECTEXTRACTION = this.API_URL_SPRING_BASE+ "/resumeVaultObjectExtraction"


  public readonly INSERTMIGRATIONTRACERECORDS = this.API_URL_SPRING_BASE+ "/insertMigrationTraceRecords"

  public readonly REINITIATEMIGRATION = this.API_URL_SPRING_BASE+ "/reInitiateMigration"

  public readonly REMIGRATE = this.API_URL_SPRING_BASE+ "/reMigrate"


  public readonly VALIDATEMAPPINGFILE = this.API_URL_SPRING_BASE+ "/ValidateMappingFile"

  public readonly DOWNLOADFILEFROMS3 = this.API_URL_SPRING_BASE+ "/downloadFile"

  public readonly RETRIEVEOBJECTFIELDSURLBYDNSID = this.API_URL_SPRING_BASE+"/retrieveObjectFieldsUrlByDnsId"
  
  public readonly DOWNLOADFILESANDZIP = this.API_URL_SPRING_BASE+ "/downloadFilesAndZip"

  public readonly DELETECASEFROMVAULT = this.API_URL_SPRING_BASE+ "/updateCaseForDeletion"

  public readonly GETGLOBALOBJECTSLISTBYDNSID = this.API_URL_SPRING_BASE+ "/GetGlobalObjectsListByDnsId"

  public readonly CREATEGLOBALOBJECTS = this.API_URL_SPRING_BASE+ "/createGlobalObjects"


  // Extraction Schedule
  public readonly INSERT_EXTRACTION_SCHEDULE_URL = this.API_URL_SPRING_BASE + "/insertExtractionSchedule";

  public readonly EXTRACTION_SCHEDULE_LIST = this.API_URL_SPRING_BASE + "/extractionScheduleList";
  public readonly EXTRACTIONSCHEDULELISTBYSCHEDULEFORANDREPORTTEMPLATE = this.API_URL_SPRING_BASE + "/extractionScheduleListByScheduleForAndReportTemplate"

  public readonly DELETE_EXTRACTION_SCHEDULE_URL =  this.API_URL_SPRING_BASE +"/deleteExtractionSchedule"

  public readonly RETERIVEINCREMENTALEXTRACTIONHISTORY =  this.API_URL_SPRING_BASE +"/reteriveIncrementalExtractionHistory"

  public readonly RETRIEVE_COMMON_REPORT_URL = this.API_URL_services + "/retrieveCommonReports";

  public readonly GETLISTBYMULTIPLEWHERECLAUSE =  this.API_URL_services + "/getlistByMultiplewhereclause";

  public readonly RUNCUSTOMREPORTGENERATIONMANUALLY= this.API_URL_SPRING_BASE + "/runCustomReportGenerationManually"

  public readonly INSERTUPDATETESTEXECUTION= this.API_URL_SPRING_BASE + "/insertUpdateTestExecution"

  public readonly RETRIEVETESTEXECUTIONS= this.API_URL_SPRING_BASE + "/retrieveTestExecutions"

  public readonly DELETETESTEXECUTION= this.API_URL_SPRING_BASE + "/deleteTestExecution"

  public readonly RETRIEVETESTEXECUTIONBYCYCLEMSTRID = this.API_URL_SPRING_BASE + "/retrieveTestExecutionByCycleMstrId"

  public readonly GETLOOKUPVAULEBYLOOKUPTYPE = this.API_URL_SPRING_BASE+ "/getlookupVauleByLookUpType"

  public readonly RETRIEVEVAULTBINDERTEMPLATESCOLLECTION = this.API_URL_SPRING_BASE+ "/retrieveVaultBinderTemplatesCollection"

  public readonly INSERTUPDATEREPORTTEMPLATE = this.API_URL_SPRING_BASE+ "/insertUpdateReportTemplate"

  public readonly RETRIEVEVAULTBINDERTEMPLATESECTIONS = this.API_URL_SPRING_BASE+ "/retrieveVaultBinderTemplateSections"

  public readonly RETRIEVEREPORTTEMPLATESBYDNSID = this.API_URL_SPRING_BASE+ "/retrieveReportTemplatesByDnsId"

  public readonly DELETEREPORTTEMPLATEBYID = this.API_URL_SPRING_BASE+ "/deleteReportTemplateById"

  public readonly RETERIVEFULLDATALOADLOG =  this.API_URL_SPRING_BASE +"/retrieveMasterDataLog"

  public readonly INSERTONLYREPORTINSTANCE = this.API_URL_SPRING_BASE+ "/insertOnlyReportInstance"

  public readonly GETPARENTREPORTTEMPLATEDETAILS =  this.API_URL_SPRING_BASE + "/getParentReportTemaplateDetails";

  public readonly REEXECUTEGENERATEREPORTPUSHTOVAULT = this.API_URL_SPRING_BASE + "/reExecuteGenerateReportPushToVault";

  public readonly RETRIEVEGENERATEDREPORTLIST =  this.API_URL_SPRING_BASE + "/retrieveGeneratedReportList"

  public readonly RETRIEVEALLVAULTDOCUMENTTYPES = this.API_URL_SPRING_BASE + "/retrieveAllVaultDocumentTypes";

  public readonly RETRIEVEVAULTDOCUMENTSUBTYPES = this.API_URL_SPRING_BASE + "/retrieveVaultDocumentSubTypes";

  public readonly RETRIEVEVAULTDOCUMENTCLASSIFICATIONS = this.API_URL_SPRING_BASE + "/retrieveVaultDocumentClassifications";

  public readonly SCHEDULENEXTREPORT = `${this.API_URL_SPRING_BASE}/scheduleNextReport`;

  public readonly RETRIEVE_MULTISELECT_VALUE = this.API_URL_SPRING_BASE + "/getAttributesAndValues";

  public readonly RETRIEVE_DOWNLOAD_DATASET_METADATA = this.API_URL_SPRING_BASE + "/downloadDatasetMetadata";

  public readonly RETRIEVE_ALL_OBJECTS_URL = this.API_URL_SPRING_BASE + '/retrieveAllReportingObjects';

  public readonly CREATE_FACETS_URL = this.API_URL_SPRING_BASE + '/createFacets';

  public readonly RETRIEVE_ALL_FACETS_URL = this.API_URL_SPRING_BASE + '/retrieveFacets';

  public readonly RETRIEVE_DATASET_URL = this.API_URL_SPRING_BASE + "/retrieveReportCreation";

  public readonly RETRIEVE_DATASET_URL_BINDER = this.API_URL_SPRING_BASE + "/retrieveDatasetByBinder";

  public readonly UPDATEDATASET_QUICK_SIGHT_URL=this.API_URL_SPRING_BASE +"/getDatasetapi";


  public readonly GENERATE_ANALYSIS_URL = this.API_URL_SPRING_BASE + "/generateAnalysis";

  public readonly ADHOC_REPORT = this.API_URL_SPRING_BASE+ "/generateReportPushToVault";

  public readonly REPORT_INSTANCE = this.API_URL_SPRING_BASE+ "/reportIntance"

  public readonly RETRIEVE_DATASET = this.API_URL_SPRING_BASE+"/retrieveDataset";

  public readonly CHECK_DATASET_IS_EDITABLE = this.API_URL_SPRING_BASE + '/checkDatasetIsEditable';
  
  public readonly DELETE_DATASET = this.API_URL_SPRING_BASE + '/deleteReportCreation';

  public readonly RETRIEVE_VAULT_SESSIONID = this.API_URL_SPRING_BASE+'/session'


  public readonly INSERT_DATASET_GROUP = this.API_URL_SPRING_BASE+'/createdatasetgroup';

  public readonly RETRIEVE_DATASET_GROUP = this.API_URL_SPRING_BASE+ '/retrieveDatasetGroup'

  public readonly RETRIEVE_DATASET_BY_DATASETGROUPID = this.API_URL_SPRING_BASE+ "/retrieveDatasetGroupById"

  public readonly DETELE_DATASET_GROUP = this.API_URL_SPRING_BASE+"/deleteDatasetGroup"

  public readonly UPDATE_DATASET_GROUP = this.API_URL_SPRING_BASE+"/updateDatasetGroup";

  public readonly RETRIEVE_DATASET_OBJECTS = this.API_URL_SPRING_BASE+"/retrieveDatasetObjects";

  public readonly GET_GLOBAL_OBJECTS=this.API_URL_SPRING_BASE+"/retrieveMigrationGlobalObjectsByExtractionType";

  public readonly RETRIEVE_REFRESH_HISTORY = this.API_URL_SPRING_BASE+"/refreshHistory";

  public readonly RETRIEVE_REFRESH_STATUS = this.API_URL_SPRING_BASE+"/objectsRefreshStatus";

  public readonly RETRIEVE_VAULTCONFIG_DETAILS = this.API_URL_SPRING_BASE+ "/retrieveVaultConfigMstrDetails";

  public readonly UPDATE_AUTORENEW_SESSION = this.API_URL_SPRING_BASE + "/updateAutoRenewSession"

  public readonly STOP_SESSION_AUTORENEW = this.API_URL_SPRING_BASE+ "/deleteSessionUpdateSchedular"

  public readonly START_SESSION_AUTORENEW_SCHEDULAR = this.API_URL_SPRING_BASE+"/startAutoSessionRenewSchedular"

  public readonly RETRIEVEEXTRACTIONTRANSFORMATIONINSTRUCTION=this.API_URL_SPRING_BASE+"/retrievePrepareExtractionTransformationInstruction/"

  public readonly RETRIEVE_ALL_OBJECTGROUPNAME= this.API_URL_SPRING_BASE+"/retrieveAllObjectGroup"

  public readonly INSERT_MSTR_OBJECT_GROUP = this.API_URL_SPRING_BASE+'/createMstrObjectGroup';

  public readonly RETRIEVE_ALL_OBJECTGROUP = this.API_URL_SPRING_BASE +"/retrieveAllMstrObjectGroup";

  public readonly RETRIEVE_VAULTOBJECTS_GROUP = this.API_URL_SPRING_BASE +"/retrieveObjectGroupDetails";

  public readonly RETRIEVE_GROUP_OBJECTS = this.API_URL_SPRING_BASE + '/retrieveGroupObjects';

  public readonly RETRIEVE_JSUITE_PROPERTY=  this.API_URL_SPRING_BASE  +"/retrieveProperties";


  public readonly RETRIEVEINSTRUCTIONSBASEDONACTIVITY=this.API_URL_SPRING_BASE+"/retrievePreparedInstructions/"

  public readonly RETRIEVE_SCRAPING_WEB_PAGES=this.API_URL_SPRING_BASE+"/retrieveScrapingWebPages"
  public readonly INSERT_SCRAPING_WEB_PAGES=this.API_URL_SPRING_BASE+"/insertIntoScrapingWebPages"

  public readonly RETRIEVE_SCRAPING_HISTORY=this.API_URL_SPRING_BASE+"/retrieveScrapingHistory"
  public readonly RETRIEVE_COMPARE_HISTORY=this.API_URL_SPRING_BASE+"/retrieveCompareHistory"
  public readonly INSERT_SCRAPING_HISTORY=this.API_URL_SPRING_BASE+"/insertIntoScrapingHistory"
  public readonly INSERT_COMPARE_HISTORY=this.API_URL_SPRING_BASE+"/insertIntoCompareHistory"
  public readonly SCRAPE_WEB_PAGE=this.API_URL_SPRING_BASE+"/scrapeTheWebpage"
  public readonly Compare_History_PAGE=this.API_URL_SPRING_BASE+"/generateCompareReport"
  public readonly RETRIEVE_SYSTEMNAME = this.API_URL_SPRING_BASE+"/retrieveSystemName"
  public readonly DOWNLOAD_COMPARE_REPORT_DIRECTORY = this.API_URL_SPRING_BASE+"/downloadCompareReportDirectory"



  public readonly RETRIEVE_SOURCE_AND_TARGET = this.API_URL_SPRING_BASE+"/retrieveSourceAndTarget"
  
  public readonly GET_OBJECTGROUP_NAMES = this.API_URL_SPRING_BASE+"/getObjectGroups"
//Access Management url
  public readonly RETRIVE_DNS=this.API_URL_SPRING_BASE+"/retriveDns"
  public readonly RETRIVE_SECRET_AND_REGION_BASEDON_DNSID=this.API_URL_SPRING_BASE+"/retrieveSecretDetails"
  public readonly RETRIVE_DNS_USERS=this.API_URL_SPRING_BASE+"/retriveUsers"
  public readonly SAVE_DNS_USER=this.API_URL_SPRING_BASE+"/dnsuser"
  public readonly RETRIVE_DNS_USER_RECORD=this.API_URL_SPRING_BASE+"/retriveAcess"
  public readonly RETRIVE_LOG_IN_USER=this.API_URL_SPRING_BASE+"/retriveloginuser"
  public readonly DELETE_RECORD=this.API_URL_SPRING_BASE+"/deleteAccessRecord"
  public readonly INSERT_ONESIGNAL_USER=this.API_URL_SPRING_BASE+"/insertUserPlayerId"
  
  // public readonly SCRAPE_WEB_PAGE=this.API_URL_SPRING_BASE+"/scrapeTheWebpage"
  // public readonly Compare_History_PAGE=this.API_URL_SPRING_BASE+"/generateCompareReport"
  
  public readonly REINITIATEMIGRATIONFORINCREMENTALLOAD=this.API_URL_SPRING_BASE+"/reIntiateMigrationForIncrementalLoad";

  public readonly RETRIEVE_About_record=this.API_URL_services+"/retriveabout"
  public readonly RETRIEVE_LOOKUPVALUE = this.API_URL_SPRING_BASE+"/retrieveLookupvalue";
  public readonly RETRIEVE_VAULT_VERSIONS = this.API_URL_SPRING_BASE+"/vaultVersion";
  public readonly UPDATE_VAULT_VERSION = this.API_URL_SPRING_BASE+"/updateVaultVersion";
  public readonly RETRIEVE_SCRIPTS_VERSION = this.API_URL_SPRING_BASE+"/retrieveScriptVersions";


  // vault Object Relationship Apis
  public readonly RETRIEVE_VAULT_OBJECT_RELATIONSHIP=this.API_URL_SPRING_BASE+"/getCompleteVaultObjectRelationshipList"
  public readonly INSERT_VAULT_OBJECT_RELATIONSHIP=this.API_URL_SPRING_BASE+"/insertIntoVaultObjectRelationship"
  public readonly UPDATE_VAULT_OBJECT_RELATIONSHIP=this.API_URL_SPRING_BASE+"/updateVaultObjectRealtionship"
  public readonly DELETE_VAULT_OBJECT_RELATIONSHIP=this.API_URL_SPRING_BASE+"/deleteVaultObjectRealtionship"
  public readonly DOWNLOAD_VAULT_OBJECT_RELATIONSHIP=this.API_URL_SPRING_BASE+"/downloadVaultObjectRelationshipListUrl"

  public readonly SAVE_RUNNING_SERVICE = this.API_URL_SPRING_BASE+"/saveRunningservice"
  public readonly RETRIVE_RUNNING_SERVICE = this.API_URL_SPRING_BASE+"/retriveRunningservice"
  public readonly RETRIVE_AVAILABLE_SERVICE = this.API_URL_SPRING_BASE+"/availableService"

  public readonly SAVE_AVAILABLE_SERVICE = this.API_URL_SPRING_BASE+"/saveAvailableservice"
  public readonly RETRIVE_REMAIN_SERVICE = this.API_URL_SPRING_BASE+"/retriveRemainningservice"

  public readonly RETRIEVE_PREVALIDATE_CONFIGURATIONS=this.API_URL_SPRING_BASE+"/retrievePropertiesBasedOnDnsIdAndMigrationId/"
  public readonly VALIDATE_CONFIGURATIONS=this.API_URL_SPRING_BASE+"/validateProperties/"
  public readonly RETRIEVE_PREVALIDATE_CONFIGURATION_BY_PROPERTYTYPE=this.API_URL_SPRING_BASE+"/retrievePreValidateConfiguartionsBasedOnPropertyType/"
  public readonly SAVE_PREVALIDATE_INSTRUCTION=this.API_URL_SPRING_BASE+"/savePreValidationConfiguration/"

  public readonly RETRIVE_SECRET = this.API_URL_SPRING_BASE+"/retriveSecrets"

  public readonly CREATE_SECRET = this.API_URL_SPRING_BASE+"/createSecrets"
  public readonly SAMPLETEST = this.API_URL+"/sampleTest"
  public readonly DECRYPT_USERNAME = this.API_URL+"/decryptUsername"
  public readonly searchCase = this.API_URL_SPRING_BASE+"/searchCaseNumber"
  public readonly CASE_JSON_HTML = this.API_URL_SPRING_BASE+"/partitionFileJson"
  public readonly STORE_CATALOG= this.API_URL_SPRING_BASE+"/storeCatalog"
  public readonly RETRIEVE_ARISj_CATALOG_CSV= this.API_URL_SPRING_BASE+"/retrieveARISjCatalogCsv"
  public readonly RETRIEVE_ARISg_CATALOG_CSV= this.API_URL_SPRING_BASE+"/retrieveARISgCatalogCsv"
  public readonly GET_MATCHING_CASE = this.API_URL_SPRING_BASE+"/getMatchingCase"

  public readonly GET_AUDIT_MATCHING_CASE= this.API_URL_SPRING_BASE+"/getAuditTrailMatchingCase"
  public readonly AUDIT_TRAIL_HTML = this.API_URL_SPRING_BASE+"/auditTrailCsvTohtml"
  public readonly STORE_AUDIT_CATALOG = this.API_URL_SPRING_BASE+"/storeAuditCatalog"

  public readonly REFRESH_CASE_CATALOG = this.API_URL_SPRING_BASE+"/refreshCaseCatalog"


   public readonly RETRIEVE_S3_PATH = this.API_URL_SPRING_BASE+"/retrieveS3Path"
   public readonly BUILD_PROJECT = this.API_URL_SPRING_BASE+"/buildProject"
   public readonly CHECKOUT_FILES = this.API_URL_SPRING_BASE+"/checkoutFiles"
   public readonly GET_DETAILS_BY_ID = this.API_URL_SPRING_BASE+"/getDetailsById"
   public readonly RETRIEVE_DNSNAME = this.API_URL_SPRING_BASE+"/retrieveTargetDnsNameByEnvName";

   public readonly REFRESH_DATASET = this.API_URL_SPRING_BASE + '/generateDataset';
   public readonly RETRIEVE_OBJECT_LABLES = this.API_URL_SPRING_BASE+"/retrieveObjects"
   public readonly RETRIEVE_ALIAS = this.API_URL_SPRING_BASE+"/getAttributesWithObject"
   public readonly DELETE_FILTER_RECORD = this.API_URL_SPRING_BASE+"/deleteFilterRecord"

  // mapping file generation (vault object group screen)

  public readonly GENERATE_MAPPING_FILE = this.API_URL_SPRING_BASE + '/generateMappingFile';


   public readonly RETRIEVE_SOURCE_DNS = this.API_URL_SPRING_BASE + '/retrieveSourceDnsByUserId';
   public readonly RETRIEVE_OBJECTS_BY_DNS = this.API_URL_SPRING_BASE + '/retrieveObjectsByDns';
   public readonly RETRIEVE_DNS_BY_OBJECT = this.API_URL_SPRING_BASE + '/retrieveDnsByObjects';

  public readonly RETRIEVE_ALL_OBJECTGROUPNAME_FOR_DATASET= this.API_URL_SPRING_BASE+"/retrieveAllObjectGroupForDataset";
  public readonly RETRIEVE_TABLES_FIELDS_FOR_DATASET = this.API_URL_SPRING_BASE + '/retrieveReportingTableFieldsById';

  public readonly INSERT_DELETION_MIGRATION = this.API_URL_SPRING_BASE+"/insertMigrationForDeletion"

  public readonly RETRIEVE_DETECT_OBJECT_CHANGES_HISTORY = this.API_URL_SPRING_BASE+"/detectObjectChangesHistory";

  public readonly RETRIEVE_VAULT_OBJECT_CHANGES =   this.API_URL_SPRING_BASE+"/retrieveVaultObjectChanges";

  public readonly DETECT_VAULT_OBJECTS_CHANGES = this.API_URL_SPRING_BASE + "/detectVaultObjectChanges"

  public readonly RETRIEVE_VAULT_OBJECT_CHANGES_DETECT_STATUS = this.API_URL_SPRING_BASE+"/objectChangesDetectStatus";

  public readonly IMPORT_VAULT_CHANGES = this.API_URL_SPRING_BASE + "/importVaultChanges"

  public readonly GET_UNIQUECOLUMN_DATA_FROM_S3 = this.API_URL_SPRING_BASE +"/getUniqueColumnsData"

  public readonly GET_DATA_FROM_S3_EXTERNAL_TABLE = this.API_URL_SPRING_BASE +"/getMatchingRowsFromExternalTables"
  public readonly GET_ALL_ROWS_DATA_FROM_S3_FILE = this.API_URL_SPRING_BASE +"/getAllRowsFromS3File"

  public readonly GET_OBJECTMETADATA_BY_DNSID_CATEGORY = this.API_URL_SPRING_BASE +"/getObjectListByDnsidAndCategory"
  
  public readonly IMPORT_DATASETS = this.API_URL_SPRING_BASE+"/importDataset"
  
  public readonly EXPORT_DATASETS = this.API_URL_SPRING_BASE+"/ExportData"

  public readonly RETRIEVE_IMPORT_HISTORY =   this.API_URL_SPRING_BASE+"/importhistory";

  public readonly UPDATE_DATASET_VISUALS=this.API_URL_SPRING_BASE+"/updateDatasetVisual"
  public readonly UPDATE_DATASET_COLUMNS_VISUALS=this.API_URL_SPRING_BASE+"/updateDatasetColumnsVisualUrl";

  public readonly REFRESH_FACET = this.API_URL_SPRING_BASE + '/generateFacets';

  public readonly CREATE_MANAGE_VISUALS = this.API_URL_SPRING_BASE + '/insertVisuals';
  public readonly RETRIEVE_MANAGE_VISUALS = this.API_URL_SPRING_BASE + '/retrieveAllManageVisuals';
  public readonly DELETE_MANAGE_VISUALS = this.API_URL_SPRING_BASE + '/deleteManageVisualsByVisualId';
  public readonly RETRIEVE_MANAGE_VISUALS_ON_DATASET_ID = this.API_URL_SPRING_BASE + '/retrieveManageVisualsByDataSetId';
  

  //retriving the screenoperations permission
  public readonly Retrivescreenoperationspermission =this.API_URL_SPRING_BASE + '/screenoperationspermission';
}
